/* src/Checkout.css */
.checkout-container {
    width: 50%;
    margin: 0 auto;
    margin-top: 23px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    background-color: #f9f9f9;
}

.checkout-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.checkout-container .customer-name {
    margin-bottom: 20px;
}

.checkout-container .customer-name label {
    display: block;
    margin-bottom: 5px;
}

.checkout-container .customer-name input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.checkout-container .course-list .course-item {
    padding: 15px;
    border-bottom: 1px solid #ccc;
}

.checkout-container .course-list .course-item h2 {
    margin-top: 0;
}

.checkout-container .total {
    text-align: right;
    margin-top: 20px;
}

.total h2 {
    margin: 0;
}

.checkout-container button {
    display: block;
    width: 100%;
    padding: 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.checkout-container button:hover {
    background-color: #45a049;
}

/* Mobile view */
@media (max-width: 767px) {
    .checkout-container {
        width: 100%;
        margin: 0;
        padding: 10px;
        scale: 0.8;
        transform-origin: center;
    }

    .checkout-container .customer-name input {
        padding: 5px;
    }

    .checkout-container .course-list .course-item {
        padding: 10px;
    }

    .checkout-container button {
        padding: 10px;
        font-size: 14px;
    }
}