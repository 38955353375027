.card{
    transition: 0.23s;
    cursor: pointer;
}

.card:hover{
transform: scale(1.022);
}

.contCard{
    background-color: #1b1a559a;
    width: 100%;
    padding: 30px;
    height: auto;
    display: flex;
    gap: 60px;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: auto;
}
.contCard img{
    height: 380px;
    width: auto;
    border-radius: 20px;
}
.contCard .text-wrap{
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 780px;

}

@media screen and (max-width:768px) {
    .contCard{
        flex-direction: column;;
    }
    .contCard img{
        width: 100%;
        height: auto;
    }
}